.moyen-title {
}

.moyen-section {
  text-align: center;
  font-weight: bold;
  font-size: larger;
  color: #980000;
}

.moyen-color {
  color: #980000;
}

.moyen-emph {
  font-weight: bold;
}
.moyen-emph-danger {
  color: var(--ion-color-danger);
}

.moyen-danger {
  font-weight: bold;
  color: var(--ion-color-danger);
}
