ion-title {
  font-size: 1.2em;
  padding-right: 0 !important;
  padding-left: 40px !important;
}

ion-card-title {
  font-size: 1.2em;
}

.emph-danger {
    color: var(--ion-color-danger);
}